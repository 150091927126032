<template>
    <div class="flex mt-5 mx-auto max-w-7xl px-5">
        <div v-if="applications.length == 0" class="flex flex-col w-full items-center py-12 bg-white rounded-lg">
            <h3>You have not applied to any job yet</h3>
            <p>Start applying for jobs today and track your applications here</p>
        </div>

        <div v-else class="flex flex-col space-y-5 w-full">
            <h3 class="">Applied jobs</h3>
            <div class="grid grid-cols-2 gap-6">
                <JobCard
                    v-for="a in applications"
                    :key="a.id"
                    :vJobId="a.job_id"
                    :vTitle="a.job_title"
                    :vCompany="a.company_name"
                    :vLocation="a.job_location"
                    :vIndustry="a.job_industry"
                    :vRole="a.job_role"
                    :vDate="a.job_date"
                    :vAppDate="a.application_date">
                </JobCard>
            </div>
        </div>
    </div>
</template>

<script>
import contractorService from '@/services/marketplace/contractor.service'
import JobCard from '@/components/marketplace/generic/JobCard'

export default {
    name: 'ContractorAppliedJobs',
    inject: ['toggleLoading'],
    components: {
        JobCard
    },
    data() {
        return {
            isLoading: true,
            applications: []
        };
    },
    methods: {
        getApplications() {
            contractorService.getApplications()
            .then(response => {
                this.applications = response.data;
            })
        }
    },
    created() {
        this.getApplications()
    }
}
</script>