import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Profiles from '../views/Profiles.vue'
import Profile from '../views/Profile.vue'
import TalentPools from '../views/TalentPools.vue'
import TalentPool from '../views/TalentPool.vue'
import Uploads from '../views/Uploads.vue'
import Logout from '../views/Logout.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/generifc/ResetPassword.vue'

// Marketplace
// Marketplace Routes
import marketplaceRoutes from './marketplace'

import ContractorProfile from '../views/marketplace/contractor/Profile.vue'
import ContractorProfileEdit from '../views/marketplace/contractor/ProfileEdit.vue'
import ContractorLogin from '../views/marketplace/contractor/Login.vue'
import ContractorLogout from '../views/marketplace/contractor/Logout.vue'
import ContractorSignup from '../views/marketplace/contractor/Signup.vue'
import CompanyLogin from '../views/marketplace/company/Login.vue'
import CompanyLogout from '../views/marketplace/company/Logout.vue'
import CompanySignup from '../views/marketplace/company/Signup.vue'
import CompanyProfiles from '../views/marketplace/company/Profiles.vue'

import store from '../store'

const generalRoutes = [
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      breadcrumbs: false,
      contractorHeader: true
    }
  },
  {
    path: '/admin',
    name: 'Dashboard',
    redirect: '/admin/profiles'
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: Login,
    meta: {
      adminHeader: true,
      breadcrumbs: false
    }
  },
  {
    path: '/admin/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/admin/profiles',
    name: 'Profiles',
    component: Profiles,
    meta: {
      adminHeader: true,
      requiresAuth: true
    }
  },
  {
    path: '/admin/profiles/:profileHash',
    name: 'Individual Profile',
    component: Profile,
    meta: {
      adminHeader: true,
      requiresAuth: true
    }
  },
  {
    path: '/admin/talent-pools',
    name: 'Talent Pools',
    component: TalentPools,
    meta: {
      adminHeader: true,
      requiresAuth: true
    }
  },
  {
    path: '/admin/talent-pools/:poolHash',
    name: 'Individual Pool',
    component: TalentPool,
    beforeEnter: (to, from, next) => {
      window.scrollTo(0, 0)
      next()
    },
    meta: { adminHeader: true }
  },
  {
    path: '/admin/uploads',
    name: 'Uploads',
    component: Uploads,
    meta: {
      adminHeader: true,
      requiresAuth: true
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
  {
    path: '/marketplace',
    name: 'Contractor Index',
    redirect: '/contractor/profile'
  },
  {
      path: '/contractor/profile',
      name: 'ContractorProfile',
      component: ContractorProfile,
      meta: {
        contractorHeader: true,
        requiresContractorAuth: true,
        breadcrumbs: false
      }
  },
  {
    path: '/contractor/profile/edit',
    name: 'ContractorProfileEdit',
    component: ContractorProfileEdit,
    meta: {
      contractorHeader: true,
      requiresContractorAuth: true,
      breadcrumbs: false
    }
  },
  {
    path: '/contractor/login',
    name: 'ContractorLogin',
    component: ContractorLogin,
    meta: {
      blankHeader: true,
      breadcrumbs: false
    }
  },
  {
    path: '/contractor/signup',
    name: 'ContractorSignup',
    component: ContractorSignup,
    meta: {
      contractorHeader: true,
      breadcrumbs: false
    }
  },
  {
    path: '/contractor/logout',
    name: 'ContractorLogout',
    component: ContractorLogout,
    meta: { contractorHeader: true }
  },
  // Company
  {
    path: '/company/login',
    name: 'CompanyLogin',
    component: CompanyLogin,
    meta: {
      blankHeader: true,
      breadcrumbs: false
    }
  },
  {
    path: '/company/signup',
    name: 'CompanySignup',
    component: CompanySignup,
    meta: {
      contractorHeader: true,
      breadcrumbs: false
    }
  },
  {
    path: '/company/profiles',
    name: 'Company profiles',
    component: CompanyProfiles,
    meta: {
      companyHeader: true,
      // requiresContractorAuth: true,
      breadcrumbs: false
      },
    },
    {
      path: '/company/logout',
      name: 'CompanyLogout',
      component: CompanyLogout,
      meta: { companyHeader: true }
    }
]

const routes = [
  ...generalRoutes,
  ...marketplaceRoutes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // See if we have the token
    if (localStorage.getItem("screenedToken") === null) {
      next({ name: 'Login' })
    } else {
      next()
    }
    // if (!store.state.isLoggedIn) {
      //   // Not logged in, redirect to login
      //   next({ name: 'Login' })
      // } else {
      //   next() // Is logged in, go ahead
      // }
  } else if (to.meta.requiresContractorAuth) {
    if (localStorage.getItem("contractorToken") === null) {
      next({ name: 'ContractorLogin' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
