<template>
    <div class="container mx-auto p-6">
      <div class="bg-white shadow-md rounded-lg p-6">
        <h2 class="text-2xl font-semibold text-gray-800 mb-4">Create a Job Posting</h2>
  
        <!-- Multi-step Job Posting Form Component -->
        <JobForm @job-submitted="handleJobSubmission" />
      </div>
    </div>
  </template>
  
  <script>
  import JobForm from '@/components/marketplace/jobs/JobForm.vue';
  import companyService from '@/services/marketplace/company.service';
  import jobsService from '@/services/marketplace/jobs.service';

  export default {
    name: 'JobPosting',
    inject: ['toggleLoading'],
    components: {
      JobForm
    },
    methods: {
      handleJobSubmission(jobData) {
        this.toggleLoading(true);
        try {
            jobsService.postJob(jobData)
                .then(response => {
                    this.toggleLoading(false);
                    this.$root.notify('Your new job post has been successfully created.', 'success');
                    this.$router.push('/company/manage-jobs');
                })
                .catch(error => {
                    this.$root.notify('Something went wrong, please try again', 'error');
                    this.toggleLoading(false);
                });
        } catch (error) {
          this.toggleLoading(false);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  button {
    transition: all 0.3s ease;
  }
  </style>
  