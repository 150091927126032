<template>
    <router-link :to="resolveLink">
        <div class="relative flex flex-col border border-2 border-gray-300 bg-white rounded-lg p-5 w-full cursor-pointer hover:border-sky-300">
        <!-- Ribbon -->
        <div v-if="vAppDate" class="absolute top-0 right-0 bg-sky-300 text-white text-xs font-semibold py-1 px-3 rounded-bl-lg rounded-tr-md">
            Applied
        </div>
    
        <!-- Job Details -->
        <p class="text-xl font-semibold">{{ vTitle }}</p>
        <p class="text-lg">{{ vCompany }}</p>
        <p class="mt-5">{{ vLocation }}</p>
        <p>{{ vIndustry }} / {{ vRole }}</p>
        <div class="flex justify-between items-center mt-3 mb-0">
            <p class="text-xs text-gray-400">{{ formattedPostDate }}</p>
            <p v-if="vAppDate" class="text-xs text-gray-400">{{ formattedApplicationDate }}</p>
        </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'JobCard',
    props: ['vJobId', 'vTitle', 'vCompany', 'vLocation', 'vIndustry', 'vRole', 'vDate', 'vAppDate'],
    computed: {
        formattedPostDate() {
            return this.formatDate(this.vDate, "Posted");
        },
        formattedApplicationDate() {
            return this.formatDate(this.vAppDate, "Applied");
        },
        resolveLink() {
            return { name: 'ContractorViewJob', params: { jobId: this.vJobId } };
        }
    },
    methods: {
        formatDate(date, label) {
            const creationDate = new Date(date);
            const now = new Date();
            const diffInMs = now - creationDate;
            const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

            if (diffInHours < 24) {
            return `${label} ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
            }

            const diffInDays = Math.floor(diffInHours / 24);
            if (diffInDays === 1) {
            return `${label} 1 day ago`;
            }

            return `${label} ${diffInDays} days ago`;
        }
    }
}
</script>