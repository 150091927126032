<template>
    <div ref="quillEditor" class="editor-container"></div>
</template>
  
<script>
import Quill from 'quill';
  
export default {
    name: 'QuillEditor',
    data() {
        return {
            editor: null,
        };
    },
    mounted() {
        this.editor = new Quill(this.$refs.quillEditor, {
            theme: 'snow', // Specify theme ('snow' or 'bubble')
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], 
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'align': [] }],
                ]
            }
        });

        this.editor.on('text-change', (delta, oldDelta, source) => {
            const html = this.editor.root.innerHTML;
            const fullDelta = this.editor.getContents(); // Get the full delta of the editor
            this.$emit('update:content', html);
            this.$emit('update:delta', fullDelta); // Emitting the full delta
        });
    }
}
</script>
  
<style>
.editor-container {
    height: 400px; /* Set the height of the editor */
}
</style>