<template>
    <div class="container mx-auto p-6">  
      <!-- Manage Jobs Header -->
      <div class="bg-white shadow-md rounded-lg p-6 mb-6">
        <h1 class="text-2xl font-semibold text-gray-800 mb-4">Manage Jobs</h1>
      </div>

      <div class="overflow-x-auto">
        <table class="min-w-full w-full bg-white border border-gray-200 rounded-lg">
          <thead>
            <tr class="">
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Job Title</th>
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Work Location</th>
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Date posted</th>
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Applicants</th>
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Status</th>
              <th class="bg-sky-500 py-4 px-6 border-b text-center text-sm font-semibold text-white">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="jobs.length > 0">
                <tr v-for="job in jobs" :key="job.id" class="hover:bg-gray-50 transition duration-150">
                <td class="py-4 px-6 border-b text-sm text-gray-900">{{ job.title }}</td>
                <td class="py-4 px-6 border-b text-sm text-gray-900">{{ job.work_location }}</td>
                <td class="py-4 px-6 border-b text-sm text-gray-900">{{ job.creation_date }}</td>
                <td class="py-4 px-6 border-b text-sm text-gray-900">{{ job.applicants }}</td>
                <td class="py-4 px-6 border-b text-sm text-gray-900">{{ job.is_active ? 'Open' : 'Closed' }}</td>
                <td class="py-4 px-6 border-b text-sm text-gray-900 ">
                  <router-link :to="`/company/view-job/${job.uuid}`">
                      <button class="bg-sky-500 text-white px-4 py-2 rounded-lg hover:bg-sky-600 transition duration-150 mr-4 shadow-md">View</button>
                  </router-link>
                  <button class="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-150 mr-4 shadow-md">Close</button>
                </td>
              </tr>
            </template>

            <tr v-else>
              <td colspan="6" class="py-6 text-center text-gray-500">
                You haven't posted any jobs yet. Start posting now to find the perfect candidates!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import companyService from '@/services/marketplace/company.service';
  
  export default {
    name: 'ManageJobs',
    inject: ['toggleLoading'],
    data() {
      return {
        jobs: []
      };
    },
    methods: {
      async fetchJobs() {
        this.toggleLoading(true);
        try {
          companyService.getJobs()
            .then(response => {
              this.jobs = response.data;
              this.toggleLoading(false);
            })
            .catch(error => {
              this.toggleLoading(false);
              console.error('Error fetching jobs:', error);
            });
        } catch (error) {
          this.toggleLoading(false);
          console.error('Error fetching jobs:', error);
        }
      },
      editJob(jobId) {
        // Navigate to job editing page or open an editing form (implement as needed)
        this.$router.push(`/edit-job/${jobId}`);
      },
    },
    mounted() {
      this.fetchJobs();
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 1200px;
  }
  th, td {
    border: 1px solid #e5e7eb;
    padding: 12px 16px;
  }
  th {
    text-align: left;
    font-size: 0.875rem;
  }
  button {
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  button:hover {
    transform: translateY(-2px);
  }
  </style>