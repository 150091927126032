import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    async postJob(data) {
        return axios.post(`${apiEndpoint}/company/post-job`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
    async getJob(jobId) {
        return axios.get(`${apiEndpoint}/company/get-job/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('companyToken'),
            }
        });
    },
}