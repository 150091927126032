<template>
    <div class="container mx-auto p-6">
      <!-- Tabbed Job Details View -->
      <div class="bg-white shadow rounded-lg p-6">
        <!-- Job Title Section -->
        <div class="flex items-center justify-between mb-6">
          <!-- Job Info -->
          <div class="flex flex-col leading-tight">
            <h2 class="text-2xl font-bold text-gray-900 mb-0">{{ jobData.title }}</h2>
            <p class="text-gray-700">{{ jobData.company_name }}</p>
            <p class="mt-1">{{ jobData.work_location }}</p>
            <p class="text-sm text-gray-500 mt-2">{{ formattedPostedDate }}</p>
            <p class="text-sm text-gray-500">Job ID: {{ jobData.uuid }}</p>
          </div>
  
          <!-- Call-to-Action Button -->
          <div>
            <button
                v-if="!hasApplied"
                @click="applyJob"
                class="px-10 py-3 text-base font-semibold text-white bg-sky-500 rounded-full shadow hover:bg-sky-600 transition-transform transform hover:scale-105">
                Apply Now
            </button>
            <p v-else class="px-10 py-3 text-base font-semibold text-gray-500 bg-gray-200 rounded-full select-none">
                You have already applied
            </p>
          </div>
        </div>
  
        <div class="border-b border-gray-200"></div>          
  
        <!-- Job Details -->
        <div>
          <div class="py-3">
            <span class="block text-gray-500 my-2">Job description</span>
            <p class="text-gray-600 mt-2" v-html="jobData.description_rendered"></p>
          </div>
  
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
            <!-- Work type -->
            <div>
              <span class="block text-gray-500">Work type</span>
              <p class="text-gray-800 capitalize">{{ jobData.work_type }}</p>
            </div>
            <!-- Contract length (optional) -->
            <div :class="jobData.work_type === 'casual' ? 'invisible' : ''">
              <span class="block text-gray-500">Length of contract</span>
              <p class="text-gray-800 capitalize">
                {{ jobData.contract_length || 'Placeholder' }}
              </p>
            </div>
            <!-- Start date -->
            <div>
              <span class="block text-gray-500">Start date</span>
              <p class="text-gray-800 capitalize">{{ formatDate(jobData.contract_start_date) }}</p>
            </div>
            <!-- Rate and rate type -->
            <div>
              <span class="block text-gray-500">Rate</span>
              <p class="text-gray-800 capitalize">{{ jobData.rate }} / {{ jobData.rate_type }}</p>
            </div>
          </div>
  
          <div class="mt-6">
            <span class="block text-gray-500">Work arrangement</span>
            <p class="text-gray-800 capitalize">{{ jobData.work_arrangement }}</p>
          </div>
  
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
            <div class="mt-6" v-if="jobData.roster">
              <span class="block text-gray-500">Roster</span>
              <p class="text-gray-800 capitalize">{{ jobData.roster }}</p>
            </div>
  
            <div class="mt-6" v-if="jobData.hours">
              <span class="block text-gray-500">Hours</span>
              <p class="text-gray-800 capitalize">{{ jobData.hours }}</p>
            </div>
          </div>
  
          <template v-if="jobData.is_project">
            <div class="mt-6">
              <span class="block text-gray-500">Project description</span>
              <p class="text-gray-800 capitalize">{{ jobData.project_description }}</p>
            </div>
  
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
              <div class="mt-6" v-if="jobData.project_timeframe">
                <span class="block text-gray-500">Project timeframe</span>
                <p class="text-gray-800 capitalize">{{ jobData.project_timeframe }}</p>
              </div>
  
              <div class="mt-6" v-if="jobData.project_stage">
                <span class="block text-gray-500">Project stage</span>
                <p class="text-gray-800 capitalize">{{ jobData.project_stage }}</p>
              </div>
  
              <div class="mt-6" v-if="jobData.project_team_size">
                <span class="block text-gray-500">Project team size</span>
                <p class="text-gray-800 capitalize">{{ jobData.project_team_size }}</p>
              </div>
  
              <div class="mt-6" v-if="jobData.project_budget">
                <span class="block text-gray-500">Project budget</span>
                <p class="text-gray-800 capitalize">{{ jobData.project_budget }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </template>
  
<script>
import contractorService from '@/services/marketplace/contractor.service';

export default {
    name: 'ViewJob',
    inject: ['toggleLoading'],
    data() {
      return {
        jobData: [],
        hasApplied: false,
        currentTab: 'job',
        tabs: [
          { id: 'job', name: 'Job' },
          { id: 'applicants', name: 'Applicants' }
        ],
        applicants: [
          { id: 1, name: 'John Doe', email: 'john.doe@example.com', status: 'Approved' },
          { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', status: 'Pending' },
          { id: 3, name: 'Michael Brown', email: 'michael.brown@example.com', status: 'Rejected' }
        ]
      };
    },
    methods: {
      async fetchJob() {
          this.toggleLoading(true);
          try {
              contractorService.getJob(this.$route.params.jobId)
              .then(response => {
                this.jobData = response.data;
                this.hasApplied = this.jobData.hasApplied
                this.toggleLoading(false);
              })
              .catch(error => {
                this.toggleLoading(false);
                console.error('Error fetching jobs:', error);
              });
          } catch (error) {
              this.toggleLoading(false);
              console.error('Error fetching jobs:', error);
          }
      },
      formatDate(timestamp) {
          if (!timestamp) return '';
          const date = new Date(timestamp * 1000); // Convert from seconds to milliseconds
          return date.toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
          });
      },
      applyJob() {
          this.toggleLoading(true);

          contractorService.applyJob(this.jobData.uuid)
          .then(response => {
              if (response.data.error) {
                this.$root.notify(response.data.errorMessage, 'error');
              } else {
                 this.hasApplied = true;
              }
          })
          .catch((error) => {
              this.$root.notify('Something went wrong while applying to the job, please try again', 'error');
          })
          .finally(() => {
            this.toggleLoading(false);
          })
      }
    },
    mounted() {
      this.fetchJob();
    },
    computed: {
      formattedPostedDate() {
        const creationDate = new Date(this.jobData.creation_date);
        const now = new Date();
        const diffInMs = now - creationDate;
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60)); // Convert ms to hours

        if (diffInHours < 24) {
          return `Posted ${diffInHours} hour${diffInHours !== 1 ? "s" : ""} ago`;
        }

        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays === 1) {
          return "Posted 1 day ago";
        }

        return `Posted ${diffInDays} days ago`;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Scoped styles */
  </style>
  