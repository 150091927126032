import axios from 'axios';

let apiEndpoint = process.env.VUE_APP_API_BASEURL

export default {
    register(formData) {
        // Sending formData using multipart/form-data
        return axios.post(`${apiEndpoint}/contractor/register`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },
    async checkEmail(emailAddress) {
        return await axios.post(`${apiEndpoint}/contractor/checkAvailableEmail`, { email: emailAddress }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
    },
    async updateContractorProfile(profileData) {
        return axios.post(`${apiEndpoint}/contractor/update-profile`, profileData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    },
    async getJobs() {
        return axios.get(`${apiEndpoint}/contractor/get-jobs`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    },
    async getJob(jobId) {
        return axios.get(`${apiEndpoint}/contractor/get-job/` + jobId, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    },
    async applyJob(jobId) {
        return await axios.post(`${apiEndpoint}/contractor/apply-job`, { jobId: jobId }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    },
    async getApplications() {
        return axios.get(`${apiEndpoint}/contractor/get-applications`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            }
        });
    },
    async getResume() {
        return axios.get(`${apiEndpoint}/contractor/get-resume`, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('contractorToken'),
            },
            responseType: 'blob'  // Tell Axios to treat the response as a Blob
        });
    }
}