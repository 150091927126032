<!-- Thank You Component for Company Registration -->
<template>
  <div class="text-center">
      <div class="leading-none tracking-tight mb-3 md:mb-4 text-2xl md:text-3xl text-gray-800">
        Thank You for Registering Your Company!
      </div>
      <!-- Message -->
      <p class="text-gray-600 mb-6">
        Your company is now registered on Screened, you are now registered as the administrator for <span class="font-bold">{{ companyName }}</span>.
      </p>
      
      <!-- Instructions -->
      <p class="text-gray-600 mb-8">
        Please log into your account to complete your registration as a hiring manager.
      </p>

      <!-- Login Button -->
      <router-link 
        to="/company/login" 
        class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-md inline-block transition duration-150 ease-in-out"
      >
        Go to Login
      </router-link>
  </div>
</template>

<script>
export default {
name: 'CompanyThankYou',
props: ['companyName'],
};
</script>

<style scoped>
/* You can reuse existing styles or add specific ones */
</style>
