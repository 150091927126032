<template>
  <header class="bg-white drop-shadow-md">
    <nav class="flex items-center justify-between text-white p-6 xl:container xl:mx-auto">
      <div class="flex">
        <a href="/company/dashboard" class="logo">
          <img class="w-1/2 ml-2" src="@/assets/logo.png" alt="Screened logo">
        </a>
      </div>

      <div class="flex space-x-6 md:flex md:items-center">
        <router-link to="/company/dashboard" class="flex items-center text-gray-500 hover:text-sky-500">
          <span class="ml-1">Dashboard</span>
        </router-link>

        <router-link to="/company/post-job" class="flex items-center text-gray-500 hover:text-sky-500">
          <span class="ml-1">Post a Job</span>
        </router-link>

        <router-link to="/company/manage-jobs" class="flex items-center text-gray-500 hover:text-sky-500">
          <span class="ml-1">Manage Jobs</span>
        </router-link>

        <router-link to="/company/logout" class="flex items-center text-gray-500 hover:text-sky-500">
          <span class="ml-1">Logout</span>
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'CompanyHeader',
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>