// Import the components
import ContractorRegistration from '../views/marketplace/contractor/Register.vue'
import ContractorForgotPassword  from '../views/marketplace/contractor/ForgotPassword.vue'
import ContractorJobs from '@/views/marketplace/contractor/Jobs.vue'
import ContractorViewJob from '@/views/marketplace/contractor/ViewJob.vue'
import ContractorAppliedJobs from '@/views/marketplace/contractor/AppliedJobs.vue'

import CompanyRegistration from '../views/marketplace/company/Register.vue'
import CompanyDashboard from '../views/marketplace/company/Dashboard.vue'
import CompanyJobPosting from '@/views/marketplace/jobs/JobPosting.vue';
import CompanyManageJobs from '@/views/marketplace/company/ManageJobs.vue';
import CompanyViewJob from '@/views/marketplace/company/ViewJob.vue'
import CompanyCandidateView from '@/views/marketplace/company/ViewCandidate.vue'

// Define the routes
const marketplaceRoutes = [
    // Contractor registration
    {
        path: '/contractor/register',
        name: 'ContractorRegistration',
        component: ContractorRegistration,
        meta: {
          blankHeader: true,
          breadcrumbs: false
        }
    },
    // Contractor Forgot password
    {
        path: '/contractor/forgot-password',
        name: 'ContractorForgotPassword',
        component: ContractorForgotPassword,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/contractor/jobs',
        name: 'ContractorJobs',
        component: ContractorJobs,
        meta: {
            contractorHeader: true,
            breadcrumbs: false
        }
    },
    {
        path: '/contractor/job/:jobId',
        name: 'ContractorViewJob',
        component: ContractorViewJob,
        meta: {
          contractorHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/contractor/applied-jobs',
          name: 'ContractorAppliedJobs',
          component: ContractorAppliedJobs,
          meta: {
            contractorHeader: true,
            breadcrumbs: false
          }
    },
    {
        path: '/company/register',
        name: 'CompanyRegistration',
        component: CompanyRegistration,
        meta: {
          blankHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/dashboard',
        name: 'CompanyDashboard',
        component: CompanyDashboard,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/post-job',
        name: 'CompanyJobPosting',
        component: CompanyJobPosting,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/manage-jobs',
        name: 'CompanyManageJobs',
        component: CompanyManageJobs,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/view-job/:jobId',
        name: 'CompanyViewJob',
        component: CompanyViewJob,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    },
    {
        path: '/company/view-job/:jobId/applicant/:profile_hash',
        name: 'CompanyCandidateView',
        component: CompanyCandidateView,
        meta: {
          companyHeader: true,
          breadcrumbs: false
        }
    }
]

export default marketplaceRoutes;