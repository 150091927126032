<template>
  <div class="container mx-auto p-6">  
    <!-- Top Navigation Buttons -->
        <!-- Profile Completion Form -->
    <div v-if="!completedProfile" class="bg-white shadow-md rounded-lg p-6 mb-6">
      <h3 class="text-xl font-semibold text-gray-800 mb-4">Complete Your Profile</h3>
      <p class="text-gray-600 mb-4">
        We’re almost there! In order to access all the dashboard features, please fill in the following details:
      </p>

      <form @submit.prevent="submitProfile" class="grid gap-6 md:grid-cols-2">
        <div>
          <label for="first_name" class="block text-gray-700 mb-2">First Name</label>
          <input
            v-model="profile.first_name"
            type="text"
            id="first_name"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your first name"
            required
          />
        </div>

        <div>
          <label for="last_name" class="block text-gray-700 mb-2">Last Name</label>
          <input
            v-model="profile.last_name"
            type="text"
            id="last_name"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your last name"
            required
          />
        </div>

        <div>
          <label for="phone" class="block text-gray-700 mb-2">Phone</label>
          <input
            v-model="profile.phone"
            type="text"
            id="phone"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your phone number"
            required
          />
        </div>

        <div>
          <label for="title" class="block text-gray-700 mb-2">Title</label>
          <input
            v-model="profile.title"
            type="text"
            id="title"
            class="w-full border border-gray-300 p-3 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            placeholder="Enter your job title"
            required
          />
        </div>

        <div class="md:col-span-2 text-right">
          <button
            type="submit"
            class="bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-150 ease-in-out"
          >
            Save Details
          </button>
        </div>
      </form>
    </div>

    <!-- Job Posts Overview Section - Hidden if Form is Visible -->
    <div v-else>
      <!-- Welcome Section -->
      <div class="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 class="text-2xl font-bold text-gray-800 mb-2">
          Welcome, {{ companyData.company_name }}!
        </h2>
        <p class="text-gray-600">
          This is your dashboard where you will manage job posts and view application insights.
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div class="bg-white shadow-md rounded-lg p-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">Total Job Posts</h3>
          <p class="text-3xl font-bold text-sky-500">{{ dashboard.total_jobs ? dashboard.total_jobs : 0 }}</p>
        </div>

        <div class="bg-white shadow-md rounded-lg p-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">Active Job Posts</h3>
          <p class="text-3xl font-bold text-green-500">{{ dashboard.active_jobs ? dashboard.active_jobs : 0 }}</p>
        </div>

        <div class="bg-white shadow-md rounded-lg p-4">
          <h3 class="text-lg font-semibold text-gray-800 mb-2">Applications</h3>
          <p class="text-3xl font-bold text-yellow-500">0</p>
        </div>
      </div>

      <!-- Prominent Call-to-Action Button -->
      <div class="mt-8 text-center">
        <!-- Motivational Message -->
        <h3 class="text-2xl font-bold text-gray-800 mb-2">Ready to Attract Top Talent?</h3>
        <p class="text-gray-600 mb-6">
            Post a new job today and reach qualified candidates who are eager to join your team.
        </p>

        <!-- Call-to-Action Button -->
        <router-link to="/company/post-job">
            <button class="bg-sky-500 text-white text-lg font-semibold py-4 px-8 rounded-lg shadow-lg hover:bg-sky-600 transition duration-300 ease-in-out transform hover:scale-105">
            Post a Job Now
          </button>
        </router-link>
      </div>

      <!-- Upcoming Features Section -->
      <div class="bg-white shadow-md rounded-lg p-6 mt-6">
        <h3 class="text-lg font-semibold text-gray-800 mb-4">Upcoming Features</h3>
        <ul class="list-disc pl-5 text-gray-600">
          <li>Manage received applications</li>
          <li>View detailed application metrics</li>
        </ul>
      </div>
    </div>
  </div>
</template>
  
<script>
import companyService from '@/services/marketplace/company.service';

export default {
  name: 'CompanyDashboard',
  inject: ['toggleLoading'],
  data() {
    return {
      // Placeholder data if needed for demo purposes
      companyData: {},
      completedProfile: true,
      profile: {},
      dashboard: {}
    };
  },
  methods: {
      async getCompanyAccount() {
          this.toggleLoading(true);
          await companyService.getCompanyAccount()
            .then(response => {
              this.companyData = response.data
              this.completedProfile = response.data.first_name === null ? false : true
              this.toggleLoading(false);
            })
            .catch(error => {
                // Handle error if the API call fails
                this.error =  true;
                this.errorMessage = 'There was an issue while submitting your information. Please try filling this form again in a few minutes'
                this.toggleLoading(false);
          });
          this.dashboard = (await companyService.getDashboard()).data;
      },
      async submitProfile() {
          this.toggleLoading(true)

          await companyService.completeRegistration(this.profile)
          this.$root.notify('Your profile information has been updated', 'success');
          this.completedProfile = true;
          this.toggleLoading(false)
      }
  },
  mounted() {
      this.getCompanyAccount()
  }
};
</script>
  
<style scoped>
/* Basic styling, adjust as needed */
</style>
